import request from './../request.js'

// 列表渲染以及查询
export function deviceList(query) {
  return request({
    url: '/iot/device/list.do?',
    method: 'get',
    params: query
  })
}
// 下载文件模板  /dev-api/iot/device/importTemplate
  export function deviceExport(data){
    return request({
      url: '/iot/device/importTemplate.do?',
      method: 'post',
      data: data,
      responseType: 'blob'
    })
  }

// 批量新增 /dev-api/iot/device/import
export function importBatchStopCardByExcel(params) {
    return request ({
        url:'iot/device/import.do?',
        method:'post',
        data:params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}

// 新增分配
export function addDevice(data) {
    return request({
      url: '/iot/device.do?',
      method: 'post',
      data: data
    })
  }
  // 删除/dev-api/iot/device/{ids}
  export function delDevice(ids) {
    return request({
        url: "/iot/device/"+ids+".do?",
        method: 'DELETE',
        data:ids,
    })
}


  // 设备详情/dev-api/iot/device/{id}
  export function newDevice(id) {
    return request({
        url: "/iot/device/"+id+".do?",
        method: 'get',
        data:id,
    })
}
// 恢复出厂/dev-api/iot/device/operation/restore
export function operationRestore(params) {
  return request ({
    url: '/iot/device/operation/restore.do?',
      method:'post',
      data:params,
      timeout:20000,
  headers:{'Content-Type': 'multipart/form-data'}
  })
}
// export function operationRestore(data) {
//   return request({
//     url: '/iot/device/operation/restore',
//     method: 'post',
//     data: data
//   })
// }

// 重启/dev-api/iot/device/operation/reboot
export function operationReboot(params) {
  return request ({
      url: '/iot/device/operation/reboot.do?',
      method:'post',
      data:params,
      timeout:20000,
  headers:{'Content-Type': 'multipart/form-data'}
  })
}
// export function operationReboot(data) {
//   return request({
//     url: '/iot/device/operation/reboot',
//     method: 'post',
//     data: data
//   })
// }
// 切卡/dev-api/iot/device/operation/switchCard
export function operationSwitchCard(params) {
  return request ({
      url: '/iot/device/operation/switchCard.do?',
      method:'post',
      data:params,
      timeout:20000,
  headers:{'Content-Type': 'multipart/form-data'}
  })
}

// export function operationSwitchCard(data) {
//   return request({
//     url: '/iot/device/operation/switchCard',
//     method: 'post',
//     data: data
//   })
// }

// 限速​/dev-api​/iot​/device​/operation​/rateLimit
// export function operationRateLimit(data) {
//   return request({
//     url: '/iot/device/operation/rateLimit',
//     method: 'post',
//     data: data
//   })
// }

export function operationRateLimit(params) {
  return request ({
      url:'/iot/device/operation/rateLimit.do?',
      method:'post',
      data:params,
      timeout:20000,
  headers:{'Content-Type': 'multipart/form-data'}
  })
}




// 编辑wifi信息/dev-api/iot/device/operation/setWifi/{operation}
// export function operationSetWifi(operationCode,deviceId,wifiIndex,ssid,password,encryptType,wifiType) {
//   const operation = {
//     operationCode,
//     deviceId,
//     wifiIndex,       
//     ssid,
//     password,
//     encryptType,
//     wifiType
//   }
//   var jsonObj = JSON.stringify(operation)
//   return request({
//     url: `/iot/device/operation/setWifi/${operationCode}.do?`,
//     method: 'post',
//     data:operation
//   })
// }

// export function operationSetSsid(setSsid,deviceId,wifiIndex,ssid) {
//   const operation = {
//     setSsid,
//     deviceId,
//     wifiIndex,       
//     ssid
//     // password,
//     // encryptType,
//     // wifiType
//   }
//   return request({
//     url: `/iot/device/operation/setWifi/${setSsid}.do?`,
//     method: 'post',
//     data:operation
//   })
// }



// 修改wifi名称
export function operationSetSsid(operationCode,deviceId,wifiIndex,ssid) {
  const operation = {
    operationCode,
    deviceId,
    wifiIndex,       
    ssid,
  }
  return request({
    url: `/iot/device/operation/setWifi/${operationCode}.do?`,
    method: 'post',
    data:operation
  })
}

// 修改wifi密码
export function operationSetPwd(operationCode,deviceId,wifiIndex,password) {
  const operation = {
    operationCode,
    deviceId,
    wifiIndex,       
    password,
  }
  return request({
    url: `/iot/device/operation/setWifi/${operationCode}.do?`,
    method: 'post',
    data:operation
  })
}

// 修改加密方式
export function operationSetType(operationCode,deviceId,wifiIndex,encryptType) {
  const operation = {
    operationCode,
    deviceId,
    wifiIndex,       
    encryptType,
  }
  return request({
    url: `/iot/device/operation/setWifi/${operationCode}.do?`,
    method: 'post',
    data:operation
  })
}









// 隐藏wifi信息/dev-api/iot/device/operation/setWifi/{operation}
export function hideOperationSetWifi(deviceId,wifiIndex,wifiType,hideWifi) {
  const operation = {
    deviceId,
    wifiIndex,
    wifiType,
    hideWifi
  }
  var jsonObj = JSON.stringify(operation)
  return request({
    url: `/iot/device/operation/setWifi/${hideWifi}.do?`,
    method: 'post',
    data:operation
  })
}

// 显示wifi信息/dev-api/iot/device/operation/setWifi/{operation}
export function showOperationSetWifi(deviceId,wifiIndex,wifiType,showWifi) {
  const operation = {
    deviceId,
    wifiIndex,
    wifiType,
    showWifi
  }
  var jsonObj = JSON.stringify(operation)
  return request({
    url: `/iot/device/operation/setWifi/${showWifi}.do?`,
    method: 'post',
    data:operation
  })
}


// 终端设备手动刷新/dev-api/iot/deviceUser/syncList
export function syncList(query) {
  return request({
      url: "/iot/deviceUser/syncList.do?",
      method: 'get',
      params: query
  })
}

// 终端操作/dev-api/iot/deviceUser/operation/{status}
export function deviceUserOperation(params) {
  // const status1 = {
  //   row,
  //   status,
  // }
  console.log(params)
  return request({
      url: `/iot/deviceUser/operation/${params.get("statusCode")}.do?`,
      method: 'post',
      data:params,
      timeout:20000,
      headers:{'Content-Type': 'multipart/form-data'}
  })
}


// 修改地图信息https://iottest.sunshineiot.net/traffic-web/iot/device/edit.do? 
export function deviceMap(id,addr,lat,lng) {
  const dataMap = {
    id,
    addr,
    lat,
    lng
  }
  return request({
      url: "/iot/device/save.do?",
      method: 'post',
      data: dataMap
  })
}

// 获取30，90，180信息 /iot/device/dayFlow/sum
export function dayFlowSum(query) {
  return request({
    url: '/iot/device/dayFlow/sum.do?',
    method: 'get',
    params: query
  })
}
// 获取列表信息 /iot/device/dayFlow/list
export function dayFlowList(query) {
  return request({
    url: '/iot/device/dayFlow/list.do?',
    method: 'get',
    params: query
  })
}


// 设备 [禁用】网络/【启用】网络  /iot/device/operation/disableNet
export function operationDisableNet(data) {
  return request({
    url: '/iot/device/operation/disableNet.do?',
    method: 'post',
    data: data
  })
}
  

// 插拔卡功能[禁用】【启用 /iot/device/operation/usimBlocked
export function operationUsimBlocked(data) {
  return request({
    url: '/iot/device/operation/usimBlocked.do?',
    method: 'post',
    data: data
  })
}

