<template>
  <div class="app-container">
    <div class="content-boxcoss">
      <div class="top-boxcoss">
        <el-row :gutter="20">
          <el-col :span="8"
            ><div class="grid-content bg-purple">
              <span>30天流量:</span>
              <span v-if="dailyTraffic._30_day_flow!=''">{{dailyTraffic._30_day_flow}} MB</span>
              <span v-else>MB</span>
              <!-- 30天流量:
              {{sizeTostr(dailyTraffic._30_day_flow)}} -->
            </div></el-col
          >
          <el-col :span="8"
            ><div class="grid-content bg-purple">
               <span>90天流量:</span>
              <span v-if="dailyTraffic._90_day_flow!=''">{{dailyTraffic._90_day_flow}} MB</span>
              <span v-else>MB</span>
              <!-- 90天流量:{{sizeTostr(dailyTraffic._90_day_flow)}} -->
            </div></el-col
          >
          <el-col :span="8"
            ><div class="grid-content bg-purple">
               <span>180天流量:</span>
              <span v-if="dailyTraffic._180_day_flow!=''">{{dailyTraffic._180_day_flow}} MB</span>
              <span v-else>MB</span>
              <!-- 180天流量:
              {{sizeTostr(dailyTraffic._180_day_flow)}} -->
            </div></el-col
          >
        </el-row>
      </div>

      <div class="tab-boxcoss">
        <div class="title-box">
          <el-form
            ref="queryParams"
            :model="queryParams"
            inline
            class="hook right themed-form search-form"
            :rules="rules"
          >
            <el-form-item>
              <el-date-picker
                v-model="searchTime"
                type="daterange"
                value-format="yyyy-MM-dd"
                @change="dateFormat"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                icon="el-icon-search"
                class="themed-button"
                @click="searchData"
                >搜索</el-button
              >
            </el-form-item>
          </el-form>
        </div>

        <el-table
          ref="table"
          border
          stripe
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%;"
          :max-height="maxTableHeight"
          :row-style="{ height: '45px' }"
          :cell-style="{ padding: '0px'}"
        >
          <el-table-column prop="dateStr" label="日期">
          </el-table-column>
          <el-table-column prop="dayFlow" label="使用流量">
            <template slot-scope="scope">
              {{scope.row.dayFlow}}MB
            </template>
          </el-table-column>
          <!-- <el-table-column
            prop="gyAgent.agentName"
            label="代理商名称"
            min-width="100"
          >
          </el-table-column> -->
          <!-- <el-table-column prop="qrcodeMode" label="二维码模式" min-width="180">
          </el-table-column>
          <el-table-column
            prop="qrcodeTextType"
            label="文本类型"
            min-width="100"
          >
          </el-table-column> -->
          <!-- <el-table-column prop="qrcodeUrl" label="qrcodeUrl" min-width="200">
          </el-table-column>
          <el-table-column prop="state" label="状态" min-width="180">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.state"
                active-value="1"
                inactive-value="0"
                @change="chg(scope.row)"
                :disabled="scope.row.state == 1"
              >
              </el-switch>
            </template>
          </el-table-column> -->
          <!-- <el-table-column prop="createTime" label="操作时间" min-width="180">
          </el-table-column>
          <el-table-column prop="createBy" label="操作人" min-width="100">
          </el-table-column>
          <el-table-column label="操作" min-width="180">
            <template slot-scope="scope">
              <el-button
                type="primary"
                class="themed-button"
                size="mini"
                @click="updata(scope.row)"
                >修改</el-button
              >
              <el-button
                type="primary"
                class="themed-button"
                size="mini"
                @click="getManagementDetails(scope.row)"
                >管理详情</el-button
              >
              <el-button
                type="primary"
                class="themed-button"
                size="mini"
                @click="delect(scope.row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column> -->
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import resize from "@/mixins/resize.js";
import { dayFlowSum, dayFlowList } from "@/api/devices/devices.js";
export default {
  mixins: [resize],
  props: ["queryDataList"],
  data() {
    return {
      tableData: [],
      dailyTraffic: {},
      deviceId: "",
      searchTime: [
        new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 30),
        new Date(),
      ],
      queryParams: {
        startDate: "",
        endDate: "",
        deviceId: "",
      },
      flowInformation: "",
     pickerOptions: {
        onPick: ({
          maxDate,  // 选择的日期范围的最大日期
          minDate // 选择的日期范围的最小日期
        }) => {
          this.selectDate = minDate.getTime()  // 将最小日期的时间戳存储在selectDate变量中
          if (maxDate) {
            this.selectDate = '' // 如果存在最大日期，则将selectDate重置为空字符串
          }
        },
        disabledDate: (time) => {
          // 如果日期的时间戳大于等于当前时间的时间戳
          if (time.getTime() >= new Date().getTime()) {
            return true // 禁用该日期 今天之后的时间不可选
          }
          if (this.selectDate !== '') {
            const one = 180 * 24 * 3600 * 1000 //  一个月的时间间隔（毫秒数）
            // 最小时间和最大时间
            const minTime = this.selectDate - one
            const maxTime = this.selectDate + one
            // 如果 time 的时间戳小于最小时间或大于最大时间，则禁用该日期
            return time.getTime() < minTime || time.getTime() > maxTime
          }
        }
      },
      rules: {
        speedLimitOne: [
          { required: true, message: "请输入限速值", trigger: "blur" },
          { pattern: /^\d+$/, message: "限速值只能为数字", trigger: "blur" },
        ],
        resource: [
          { required: true, message: "请选择操作", trigger: "change" },
        ],
      },
    };
  },
  watch: {
    queryDataList(n, o) {
      this.deviceId = n.id;
      this.queryParams.deviceId = n.id;
      this.getDayFlowSum();
      this.getDayFlowList();
    },
  },
  created() {
    this.deviceId = this.queryDataList.id;
    this.queryParams.deviceId = this.queryDataList.id;
    this.queryParams.startDate =this.formatDate (this.searchTime[0]);
    this.queryParams.endDate = this.formatDate (this.searchTime[1]);
  },
  methods: {
    formatDate(timer) {
      var date = new Date(timer); 
      date.setMinutes(date.getMinutes() - date.getTimezoneOffset()); 
      var year = date.getFullYear(); 
      var month = (date.getMonth() + 1).toString().padStart(2, "0"); 
      var day = date.getDate().toString().padStart(2, "0"); 
      return `${year}-${month}-${day}`;
    },

    getDayFlowSum() {
      this.loading = true;
      let cd = {
        deviceId: this.deviceId,
      };
      dayFlowSum(cd).then((res) => {
        this.dailyTraffic = res;
      });
    },
    dateFormat(date) {
      this.searchTime = date;
      this.queryParams.startDate = this.searchTime[0];
      this.queryParams.endDate = this.searchTime[1];
    },
    getDayFlowList() {
      // this.loading = true;
      // // let params = {
      // //   pageNum: this.page,
      // //   pageSize: this.pageSize,
      // // };
      // // Object.assign(this.queryParams,params);
      // Object.assign(this.queryParams);
      dayFlowList(Object.assign(this.queryParams)).then((res) => {
        this.tableData=res.rows
      });
    },
    searchData() {
      this.getDayFlowList();
    },
    sizeTostr(size) {
    var data = "";
    if (size >0.1 * 1024 * 1024 * 1024* 1024) {
    data = (size / (1024 * 1024 * 1024)).toFixed(2) + "GB";  
    }
    // if (size < 0.1 * 1024) { //如果小于0.1KB转化成B  
    //     data = size.toFixed(2) + "B";
    // } else if (size < 0.1 * 1024 * 1024) {//如果小于0.1MB转化成KB  
    //     data = (size / 1024).toFixed(2) + "KB";
    // } else if (size < 0.1 * 1024 * 1024 * 1024) { //如果小于0.1GB转化成MB  
    //     data = (size / (1024 * 1024)).toFixed(2) + "MB";
    // } else { //其他转化成GB  
    //     data = (size / (1024 * 1024 * 1024)).toFixed(2) + "GB";
    // }
    var sizestr = data + "";
    var len = sizestr.indexOf("\.");
    var dec = sizestr.substr(len + 1, 2);
    if (dec == "00") {//当小数点后为00时 去掉小数部分  
        return sizestr.substring(0, len) + sizestr.substr(len + 3, 2);
    }
    return sizestr;
} ,
  },
};
</script>

<style lang="scss" scoped>
.content-boxcoss {
  margin-top: 10px;
  margin-left: 30px;
  height: 100vh;
  // background: #fff;
}
.top-boxcoss {
  padding: 20px 0 20px 10px;
}
.tab-boxcoss {
  background: #fff;
}
.partial_box {
  margin: 20px 0px;
  padding-left: 20px;
  background: #fff;
}
.partial_boxcoss {
  margin-bottom: 20px;
  padding-left: 20px;
  background: #fff;
}
.partial_title {
  padding-top: 20px;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 30px;
}
.partial_content {
  padding-bottom: 20px;
}
.partial_contentOne {
  padding-bottom: 20px;
  display: flex;
}
.nowLimitSpeed {
  margin: 30px 10px;
  span {
    font-size: 14px;
  }
}
.content-boxOne {
  display: flex;
}
.conter-boder {
  min-width: 200px;
  border: 2px solid rgb(182, 182, 230);
  text-align: center;
  padding-top: 10px;
  margin: 0 20px;
  p {
    font-size: 12px;
    height: 30px;
    line-height: 30px;
  }
}
.card_information {
  display: flex;
  .card_unicom {
    margin: 0 20px;
  }
}
.Intelligent_box {
  position: relative;
}
.Intelligent {
  position: absolute;
  top: 35%;
  left: 33%;
}
.title-box {
  border-bottom: none !important;
}
</style>