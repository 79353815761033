<template>
  <div class="app-container">
     <div class="content-box">
     <el-button type="primary" @click="getRestore" style="background:#f06400;border-color: #f06400;">恢复出厂</el-button>
    </div>
  </div>
</template>
<script>
import { operationRestore } from "@/api/devices/devices.js";
export default {
  props: ["queryDataList"],
  data() {
    return {
      restoreOne: "",
    };
  },
  watch: {
    queryDataList(n, o) {
      this.restoreOne = n;
    },
  },

  created() {
    this.restoreOne = this.queryDataList;
  },

  methods: {
    getRestore() {
      this.$confirm("是否恢复出厂设置", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let paramValue = new FormData();
          paramValue.append("deviceId", this.restoreOne.id);
          operationRestore(paramValue).then((res) => {
            if (res.code == "0") {
              this.$message({
                message: "提交请求成功",
                type: "success",
              });
              this.$router.push("/equipmentManagement/taskList");
            } else {
              this.$message({
                message: res.msg,
                type: "error",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.content-box{
margin-top: 10px;
margin-left: 30px;
height: 100vh;
}
</style>