<template>
  <div class="app-container">
   <div class="content-box">
     <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="50px"
      class="demo-ruleForm"
    >
      <div class="nowLimitSpeed">
      <span>当前限速值:</span>
      <span>{{limitSpeedOne}}</span>
      </div>
      <el-form-item label="操作" prop="resource">
        <el-radio-group v-model="ruleForm.resource">
          <el-radio :label="0">限速</el-radio>
          <el-radio :label="1" v-show="limitSpeedOne!=0">解除</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="限速" prop="speedLimitOne" v-show="ruleForm.resource == 0">
        <el-input v-model="ruleForm.speedLimitOne" style="width:200px; margin-right:10px" ></el-input>
        <span>kbps</span>
      </el-form-item>
 

    <el-form-item>
      <el-button type="primary" @click="getLimit" size="mini" style="background:#f06400;border-color: #f06400; ">保存</el-button>
      <el-button @click="secure" size="mini"  v-show="limitSpeedOne!=0">解除限速</el-button>
    </el-form-item>
   </el-form>
   </div>
  </div>
</template>
<script>
import { operationRateLimit } from "@/api/devices/devices.js";
export default {
  props: ["queryDataList"],
  data() {
    return {
      ruleForm: {
      resource: "",
      speedLimitOne:"",
      deviceId:""
      },
      radio: 0,
      speedLimitCon: "",
      limitSpeedOne:"",
        rules: {
        speedLimitOne: [
          { required: true, message: "请输入限速值", trigger: "blur" },
          { pattern:/^\d+$/ , message: "限速值只能为数字", trigger: "blur" },
        ],
        resource: [
            { required: true, message: '请选择操作', trigger: 'change' }
          ],
      }
    };
  },
  watch: {
    queryDataList(n, o) {
      this.speedLimitCon = n;
      this.limitSpeedOne=this.speedLimitCon.limitSpeed
    },
  },
  created() {
    this.speedLimitCon = this.queryDataList;
    this.limitSpeedOne=this.speedLimitCon.limitSpeed
    console.log(this.limitSpeedOne,12121212)
  },
  methods: {
    getLimit() {
        this.$refs["ruleForm"].validate(valid => {
        this.ruleForm.deviceId=this.speedLimitCon.id
        if (valid) {
          let paramValue = new FormData();
          paramValue.append("deviceId", this.ruleForm.deviceId);
          paramValue.append("resource", this.ruleForm.resource);
          paramValue.append("speedLimit", this.ruleForm.speedLimitOne);
          operationRateLimit(paramValue).then((res) => {
        if (res.code == "0") {
          this.$message({
            message: "提交请求成功",
            type: "success",
          });
          this.$router.push("/equipmentManagement/taskList");
        } else {
          this.$message({
            message: res.msg,
            type: "error",
          });
        }
      }); 
        }
        })
    },
     secure() {
        this.ruleForm.speedLimitOne="0"
        this.$refs["ruleForm"].validate(valid => {
        this.ruleForm.deviceId=this.speedLimitCon.id
        if (valid) {
          let paramValue = new FormData();
          paramValue.append("deviceId", this.ruleForm.deviceId);
          paramValue.append("resource", this.ruleForm.resource);
          paramValue.append("speedLimit", this.ruleForm.speedLimitOne);
          operationRateLimit(paramValue).then((res) => {
        if (res.code == "0") {
          this.$message({
            message: "提交请求成功",
            type: "success",
          });
          this.$router.push("/equipmentManagement/taskList");
        } else {
          this.$message({
            message: res.msg,
            type: "error",
          });
        }
      }); 
        }
        })
    },
  },
};
</script>

<style lang="scss" scoped>
.content-box{
margin-top: 10px;
margin-left: 30px;
height: 100vh;
}
.nowLimitSpeed{
margin:30px 10px;
span{
font-size:14px;  
}
}
</style>