<template>
  <div class="app-container">
    <div class="content-box">
      <div
        class="choose-option"
        v-for="(item, index) in wifiCodeList"
        :key="index"
      >
        <el-row :gutter="20">
          <el-col :span="24"
            ><div class="grid-content bg-purple ugnegOne">
              <div class="wifiButton">
                <!-- <el-button
                  class="wifiButtonOne"
                  type="primary"
                  size="mini"
                  @click="handleUpdate(item)"
                  >编辑</el-button
                > -->
                <el-button
                  class="wifiButtonOne"
                  type="primary"
                  size="mini"
                  v-show="item.broadcastStatus == 1"
                  @click="gethideWifi(item)"
                  >隐藏</el-button
                >
                <el-button
                  class="wifiButtonOne"
                  type="primary"
                  size="mini"
                  v-show="item.broadcastStatus == 0"
                  @click="getShowWifi(item)"
                  >显示</el-button
                >
              </div>

              <div>
                <el-descriptions
                  class="margin-top"
                  :column="6"
                  direction="vertical"
                  title="wifi"
                >
                  <el-descriptions-item label="WIFI名称">
                    <!--  oninput ="value=value.replace(/[^\w_]/g,'')" -->
                    <el-input
                      v-model="item.ssid"
                      placeholder="请输入名称"
                      @blur="ssidBlur(item)"
                      @focus="focusSsid(item)"
                      maxlength="16"
                      minlength="8"
                    ></el-input>
                  </el-descriptions-item>
                  <el-descriptions-item label="WIFI密码">
                    <!--  oninput ="value=value.replace(/\D/g,'')" -->
                    <el-input
                      v-model="item.password"
                      placeholder="请输入密码"
                      @blur="passwordBlur(item)"
                      @focus="focusPassword(item)"
                      maxlength="12"
                      minlength="8"
                    ></el-input>
                  </el-descriptions-item>
                  <el-descriptions-item label="加密类型">
                    <el-select
                      v-model="item.encryptType"
                      placeholder="请选择加密方式"
                      style="width: 150px"
                      @focus="focusType(item)"
                      @change="typeBlur(item, index)"
                    >
                      <el-option
                        v-for="ele in Encryptions"
                        :key="ele.value"
                        :label="ele.label"
                        :value="ele.value"
                      >
                      </el-option>
                    </el-select>
                  </el-descriptions-item>
                  <!-- <el-descriptions-item label="WIFI名称">{{
                    item.ssid
                  }}</el-descriptions-item>
                  <el-descriptions-item label="WIFI密码">{{
                    item.password
                  }}</el-descriptions-item>
                    <el-descriptions-item label="加密类型">{{
                    item.encryptType
                  }}</el-descriptions-item> -->
                  <el-descriptions-item label="WIFI类型">{{
                    item.wifiType == "24G" ? "2.4G" : "5G"
                  }}</el-descriptions-item>
                  <el-descriptions-item label="是否启用">{{
                    item.wifiEnabled === "1" ? "在线" : "离线"
                  }}</el-descriptions-item>
                  <el-descriptions-item label="最大连接数">{{
                    item.maxClients
                  }}</el-descriptions-item>
                  <el-descriptions-item label="当前连接数">{{
                    item.clientNumber
                  }}</el-descriptions-item>
                </el-descriptions>
              </div>
            </div></el-col
          >
        </el-row>
      </div>
    </div>

    <!-- 编辑 -->
    <!-- <el-dialog
      title="编辑"
      :visible.sync="dialogVisible"
      width="600px"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="150px">
        <el-form-item label="修改类型" prop="operationCon">
          <el-select
            v-model="form.operationCon"
            placeholder="请选择修改类型"
            style="width: 80%"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="wifi名称"
          prop="setSsid"
          v-if="form.operationCon == 1"
        >
          <el-input
            placeholder="wifi名称"
            v-model="form.setSsid"
            autocomplete="off"
            style="width: 80%"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="setPwd" v-if="form.operationCon == 2">
          <el-input
            placeholder="密码"
            v-model="form.setPwd"
            autocomplete="off"
            style="width: 80%"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="加密方式"
          prop="setEncrypt"
          v-if="form.operationCon == 3"
        >
          <el-select
            v-model="form.setEncrypt"
            placeholder="请选择加密方式"
            style="width: 80%"
          >
            <el-option
              v-for="item in Encryptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel" size="small"  type="info" class="themed-button" >取 消</el-button>
        <el-button type="primary"  @click="submitForm" size="small" class="themed-button" 
          >确 定</el-button
        >
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
import {
  operationSetSsid,
  operationSetPwd,
  operationSetType,
  hideOperationSetWifi,
  showOperationSetWifi,
} from "@/api/devices/devices.js";
export default {
  props: ["queryDataList"],
  data() {
    return {
      dialogVisible: false,
      form: {
        deviceCodeId: "",
        wifiCodeIndex: "",
        setEncrypt: "",
        setSsid: "",
        setPwd: "",
        wifiType: "",
      },
      //query_data:{},
      wifiCodeList: [],
      options: [
        {
          value: "1",
          name: "setSsid",
          label: "wifi名称",
        },
        {
          value: "2",
          name: "setPwd",
          label: "密码",
        },
        {
          value: "3",
          name: "setEncrypt",
          label: "加密方式",
        },
      ],
      value: "",
      Encryptions: [
        {
          value: "",
          label: "",
        },
      ],
      wifiListOne: "",
      formList: {
        deviceCodeId: "",
        showWifi: "showWifi",
        wifiCodeIndex: "",
      },
      formListTwo: {
        deviceCodeId: "",
        hideWifi: "hideWifi",
        wifiCodeIndex: "",
      },
      rules: {
        setSsid: [
          { required: true, message: "请输入wifi名称", trigger: "blur" },
        ],
        setPwd: [{ required: true, message: "请输入密码", trigger: "blur" }],
        operationCon: [
          { required: true, message: "请选择修改类型", trigger: "change" },
        ],
        setEncrypt: [
          { required: true, message: "请选择加密方式", trigger: "change" },
        ],
      },
    };
  },
  watch: {
    queryDataList(n, o) {
      this.wifiListOne = JSON.parse(JSON.stringify(n));
      this.wifiCodeList = this.wifiListOne.wifiList;
    },
  },
  mounted() {
    this.getWifiCodeList();
  },
  methods: {
  getWifiCodeList() {
      this.wifiListOne = this.queryDataList;
      this.wifiCodeList = this.wifiListOne.wifiList;
    },
    // onFocus(item) {
    //   console.log(item, 333333);
    //   this.Encryptions = [];
    //   let a_encryptTypeList = item.encryptTypeList.split(",");
    //   a_encryptTypeList.forEach((itemc) => {
    //     this.Encryptions.push({
    //       label: itemc,
    //       value: itemc,
    //     });
    //   });
    // },
    gethideWifi(item) {
      this.formListTwo.deviceCodeId = item.deviceId;
      this.formListTwo.wifiCodeIndex = item.wifiIndex;
      this.formListTwo.wifiCodeType = item.wifiType;
      // this.formList.showWifi=showWifi
      hideOperationSetWifi(
        this.formListTwo.deviceCodeId,
        this.formListTwo.wifiCodeIndex,
        this.formListTwo.wifiCodeType,
        this.formListTwo.hideWifi
      ).then((response) => {
        if (response.code == "0") {
          this.$modal.msgSuccess("操作成功");
          this.$router.push("/equipmentManagement/taskList");
        }
      });
    },
    getShowWifi(item) {
      this.formList.deviceCodeId = item.deviceId;
      this.formList.wifiCodeIndex = item.wifiIndex;
      this.formList.wifiCodeType = item.wifiType;
      showOperationSetWifi(
        this.formList.deviceCodeId,
        this.formList.wifiCodeIndex,
        this.formList.wifiCodeType,
        this.formList.showWifi
      ).then((response) => {
        if (response.code == "0") {
          this.$modal.msgSuccess("操作成功");
          this.$router.push("/equipmentManagement/taskList");
        }
      });
    },
    cancel() {
      this.dialogVisible = false;
    },
    // 修改名称
    focusSsid(e) {
      e.oldSsid = e.ssid;
    },
    ssidBlur(e) {
      if (e.ssid !== e.oldSsid) {
        this.$confirm("是否修改名称", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
               //var re=/^[\u4e00-\u9fa5_a-zA-Z0-9-_.]{1,32}$/
               var re=/^[0-9a-zA-Z_.-]{1,32}$/
            if (!re.test(e.ssid)){
               this.$alert("只能为1到32位,包含数字字母下划线点连接符", "提示", {
                  confirmButtonText: "确定",
                })
                e.ssid = e.oldSsid;
            }else{
              operationSetSsid("setSsid", e.deviceId, e.wifiIndex, e.ssid).then(
              (res) => {
                if (res.code == "0") {
                  this.$modal.msgSuccess("修改成功");
                  this.$router.push("/equipmentManagement/taskList");
                }
              }
            );
            }
            // operationSetSsid("setSsid", e.deviceId, e.wifiIndex, e.ssid).then(
            //   (res) => {
            //     if (res.code == "0") {
            //       this.$modal.msgSuccess("修改成功");
            //       this.$router.push("/equipmentManagement/taskList");
            //     }
            //   }
            // );
          })
          .catch(() => {
            e.ssid = e.oldSsid;
          });
      }
    },

    // 修改密码
    focusPassword(e) {
      e.oldPassword = e.password;
    },
    passwordBlur(e) {
      if (e.password !== e.oldPassword) {
        this.$confirm("是否修改密码", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            var re=/^[a-zA-Z0-9!@#%*&]{8,64}$/;
              if(!re.test(e.password)){
              this.$alert("只能为英文数字以及!@#%*&符号且长度为8到64位", "提示", {
              confirmButtonText: "确定",
              })
               e.password = e.oldPassword;
              }else{
              operationSetPwd("setPwd", e.deviceId, e.wifiIndex, e.password).then(
              (res) => {
                if (res.code == "0") {
                  this.$modal.msgSuccess("修改成功");
                  this.$router.push("/equipmentManagement/taskList");
                }
              }
            );  
              }
            // operationSetPwd("setPwd", e.deviceId, e.wifiIndex, e.password).then(
            //   (res) => {
            //     if (res.code == "0") {
            //       this.$modal.msgSuccess("修改成功");
            //       this.$router.push("/equipmentManagement/taskList");
            //     }
            //   }
            // );
          })
          .catch(() => {
            e.password = e.oldPassword;
          });
      }
    },

    // 修改加密方式
    focusType(e) {
      this.Encryptions = [];
      let a_encryptTypeList = e.encryptTypeList.split(",");
      a_encryptTypeList.forEach((itemc) => {
        this.Encryptions.push({
          label: itemc,
          value: itemc,
        });
      });
      e.oldEncryptType = e.encryptType;
    },
    typeBlur(e, index) {
      if (e.encryptType != e.oldEncryptType) {
        this.$confirm("是否进行修改", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            operationSetType(
              "setEncrypt",
              e.deviceId,
              e.wifiIndex,
              e.encryptType
            ).then((res) => {
              if (res.code == "0") {
                this.$modal.msgSuccess("修改成功");
                this.$router.push("/equipmentManagement/taskList");
              }
            });
          })
          .catch(() => {
            this.wifiCodeList = JSON.parse(
              JSON.stringify(this.queryDataList)
            ).wifiList;
          });
      }
    },

    // submitForm() {
    //   this.form.operationCode = this.options[this.form.operationCon * 1 - 1].name;
    //   this.$refs["form"].validate((valid) => {
    //     if (valid) {
    //       operationSetWifi(
    //         this.form.operationCode,
    //         this.form.deviceCodeId,
    //         this.form.wifiCodeIndex,
    //         this.form.setSsid,
    //         this.form.setPwd,
    //         this.form.setEncrypt,
    //         this.form.wifiType,
    //       ).then((response) => {
    //         if (response.code == "0") {
    //           this.$modal.msgSuccess("编辑成功");
    //           this.$router.push("/equipmentManagement/taskList");
    //         }
    //       });
    //     }
    //   });
    //   this.dialogVisible = false;
    // },
  },
};
</script>

<style lang="scss" scoped>
.choose-title {
  height: 50px;
  line-height: 50px;
  color: rgb(255, 151, 2);
}
.content-box {
  margin-left: 25px;
  margin-top: 10px;
  height: 100vh;
}
.choose-option {
  margin-top: 10px;
  height: 230px;
  background: #fff;
}
.ugnegOne {
  background: #fff;
  padding-top: 10px;
  padding-left: 20px;
}
::v-deep .el-descriptions__header {
  padding-top: 10px;
  padding-left: 20px;
  margin-bottom: 0px;
}
::v-deep .ugnegOne .el-descriptions .el-descriptions__body {
  background: #fff;
}
::v-deep .ugnegOne .el-descriptions .el-descriptions__body {
  height: auto;
  background: #fff;
}
::v-deep
  .ugnegOne
  .el-descriptions--medium:not(.is-bordered)
  .el-descriptions-item__cell {
  padding-bottom: 0px;
  padding-left: 25px;
}
::v-deep .ugnegOne .has-colon {
  padding-top: 20px;
  font-weight: 600;
}
.wifiButton {
  position: absolute;
  right: 3%;
}
.wifiButtonOne {
  margin-right: 15px;
  background: #f06400;
  border-color: #f06400;
}
::v-deep.el-input--small .el-input__inner {
  width: 150px;
}
</style>