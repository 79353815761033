<template>
  <div class="materialsList fadeInRight content-box">
   <div>
      <div class="content-box">
      <div
        class="choose-option"
        v-for="(item, index) in wifiCodeList"
        :key="index"
      >
        <el-row :gutter="20">
          <el-col :span="24"
            ><div class="grid-content bg-purple ugnegOne">
              <div class="wifiButton">
                <!-- <el-button
                  class="wifiButtonOne"
                  type="primary"
                  size="mini"
                  @click="handleUpdate(item)"
                  >编辑</el-button
                > -->
                <el-button
                  class="wifiButtonOne"
                  type="primary"
                  size="mini"
                  v-show="item.broadcastStatus == 1"
                  @click="gethideWifi(item)"
                  >隐藏</el-button
                >
                <el-button
                  class="wifiButtonOne"
                  type="primary"
                  size="mini"
                  v-show="item.broadcastStatus == 0"
                  @click="getShowWifi(item)"
                  >显示</el-button
                >
              </div>

              <div>
                <el-descriptions
                  class="margin-top"
                  :column="6"
                  direction="vertical"
                  title="wifi"
                >
                  <el-descriptions-item label="WIFI名称">
                    <!--  oninput ="value=value.replace(/[^\w_]/g,'')" -->
                    <el-input
                      v-model="item.ssid"
                      placeholder="请输入名称"
                      @blur="ssidBlur(item)"
                      @focus="focusSsid(item)"
                      maxlength="16"
                      minlength="8"
                    ></el-input>
                  </el-descriptions-item>
                  <el-descriptions-item label="WIFI密码">
                    <!--  oninput ="value=value.replace(/\D/g,'')" -->
                    <el-input
                      v-model="item.password"
                      placeholder="请输入密码"
                      @blur="passwordBlur(item)"
                      @focus="focusPassword(item)"
                      maxlength="12"
                      minlength="8"
                    ></el-input>
                  </el-descriptions-item>
                  <el-descriptions-item label="加密类型">
                    <el-select
                      v-model="item.encryptType"
                      placeholder="请选择加密方式"
                      style="width: 150px"
                      @focus="focusType(item)"
                      @change="typeBlur(item, index)"
                    >
                      <el-option
                        v-for="ele in Encryptions"
                        :key="ele.value"
                        :label="ele.label"
                        :value="ele.value"
                      >
                      </el-option>
                    </el-select>
                  </el-descriptions-item>
                  <!-- <el-descriptions-item label="WIFI名称">{{
                    item.ssid
                  }}</el-descriptions-item>
                  <el-descriptions-item label="WIFI密码">{{
                    item.password
                  }}</el-descriptions-item>
                    <el-descriptions-item label="加密类型">{{
                    item.encryptType
                  }}</el-descriptions-item> -->
                  <el-descriptions-item label="WIFI类型">{{
                    item.wifiType == "24G" ? "2.4G" : "5G"
                  }}</el-descriptions-item>
                  <el-descriptions-item label="是否启用">{{
                    item.wifiEnabled === "1" ? "在线" : "离线"
                  }}</el-descriptions-item>
                  <el-descriptions-item label="最大连接数">{{
                    item.maxClients
                  }}</el-descriptions-item>
                  <el-descriptions-item label="当前连接数">{{
                    item.clientNumber
                  }}</el-descriptions-item>
                </el-descriptions>
              </div>
            </div></el-col
          >
        </el-row>
      </div>
    </div>
   </div>



   <div>
     <div class="title-box clearfix">
      <el-row :gutter="20">
        <el-col :span="4" :xs="24" :offset="20"
          ><div class="grid-content bg-purple">
            <el-form
              :inline="true"
              :model="formInline"
              class="demo-form-inline"
              style="height: auto; line-height: 40px; margin-top:5px"
            >
              <el-form-item>
                <el-button
                  icon="el-icon-plus"
                  type="primary"
                  class="themed-button"
                  @click="refresh"
                  >刷新</el-button
                >
              </el-form-item>
            </el-form>
          </div></el-col
        >
      </el-row>
    </div>
    <div>
      <el-table
        ref="table"
        border
        stripe
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        :row-style="{ height: '45px' }"
        :cell-style="{ padding: '0px' }"
      >
        <el-table-column prop="name" label="名称" min-width="150">
        </el-table-column>
        <el-table-column prop="mac" label="MAC" min-width="150">
        </el-table-column>
        <el-table-column    prop="ip" label="IP" min-width="120">
        </el-table-column>
        <el-table-column prop="status" label="状态" min-width="120">
           <template slot-scope="scope">
          <el-tag :type="scope.row.status == 'conn' ? 'success' : 'danger'">{{ scope.row.status === "conn" ? "连接" : scope.row.status === "black" ? "黑名单":"未连接"  }}</el-tag>
        </template>
        </el-table-column>
        <el-table-column label="操作" min-width="180">
          <template slot-scope="scope">
            <el-button v-show="scope.row.status=='conn'"
              type="danger"
              class="themed-button"
              size="mini"
              @click="getBlack(scope.row)"
              >加入黑名单</el-button
            >
            <el-button  v-show="scope.row.status=='conn'"
              type="danger" 
              class="themed-button"
              size="mini"
              @click="getDisconn(scope.row)"
              >剔除用户</el-button
            >
             <el-button  v-show="scope.row.status=='black'"
              type="danger" 
              class="themed-button"
              size="mini"
              @click="getDisblack(scope.row)"
              >解除黑名单</el-button
            >
          </template>
        </el-table-column>
      </el-table>
         <!-- <el-pagination
        :current-page.sync="page"
        :page-sizes="[5, 10, 15, 50, 100, 1000]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      ></el-pagination> -->
    </div>
   </div>
  </div>
</template>

<script>
import {
  syncList,deviceUserOperation,
  operationSetSsid,
  operationSetPwd,
  operationSetType,
  hideOperationSetWifi,
  showOperationSetWifi,
} from "@/api/devices/devices.js";
export default {
  props: ["queryDataList"],
  data() {
    return {
    dialogVisible: false,
     form: {
        deviceCodeId: "",
        wifiCodeIndex: "",
        setEncrypt: "",
        setSsid: "",
        setPwd: "",
        wifiType: "",
      },
     formInline:{
      spec:"",
      material:"",
     },
      wifiCodeList: [],
      options: [
        {
          value: "1",
          name: "setSsid",
          label: "wifi名称",
        },
        {
          value: "2",
          name: "setPwd",
          label: "密码",
        },
        {
          value: "3",
          name: "setEncrypt",
          label: "加密方式",
        },
      ],
     loading: false,
      tableData: [],
      materialList:[],
      specList:[],
      page: 1,
      pageSize: 15,
      total: 0,
      deviceCount:"",
        value: "",
      Encryptions: [
        {
          value: "",
          label: "",
        },
      ],
      wifiListOne: "",
      formList: {
        deviceCodeId: "",
        showWifi: "showWifi",
        wifiCodeIndex: "",
      },
      formListTwo: {
        deviceCodeId: "",
        hideWifi: "hideWifi",
        wifiCodeIndex: "",
      },
      rules: {
        setSsid: [
          { required: true, message: "请输入wifi名称", trigger: "blur" },
        ],
        setPwd: [{ required: true, message: "请输入密码", trigger: "blur" }],
        operationCon: [
          { required: true, message: "请选择修改类型", trigger: "change" },
        ],
        setEncrypt: [
          { required: true, message: "请选择加密方式", trigger: "change" },
        ],
      },
    };
  },
  watch: {
    queryDataList(n, o) {
      // console.log(n,2323)
      //this.tableData = n.userList;
      this.deviceCount= n.id
      this.refresh()
      this.wifiListOne = JSON.parse(JSON.stringify(n));
      this.wifiCodeList = this.wifiListOne.wifiList;
    },
  },
  created() {
   //this.tableData = this.queryDataList.userList;
   this.deviceCount=this.queryDataList.id
   this.getWifiCodeList();
  },
  methods: {
      // unicode转换
//      UniDecode(row) {
//       var encodeString = row.name
//     if (undefined == encodeString) {
//         return "";
//     }
//     var deCodeStr = "";

//     var strLen = encodeString.length / 4;
//     for (var idx = 0; idx < strLen; ++idx) {
//         deCodeStr += String.fromCharCode(parseInt(encodeString.substr(idx * 4, 4), 16));
//     }
//     return deCodeStr;
// },
// UniEncode(string) {
//     if (undefined == string) {
//         return "";
//     }
//     var code = "";
//     for (var i = 0; i < string.length; ++i) {
//         var charCode = string.charCodeAt(i).toString(16);
//         var paddingLen = 4 - charCode.length;
//         for (var j = 0; j < paddingLen; ++j) {
//             charCode = "0" + charCode;
//         }

//         code += charCode;
//     }
//     return code;
// },

getWifiCodeList() {
      this.wifiListOne = this.queryDataList;
      this.wifiCodeList = this.wifiListOne.wifiList;
    },
// ---------------------------
    // 刷新
    refresh(){
      let cd = {
      deviceId: this.deviceCount,
      };
    this.loading = true;
    syncList(cd).then((res)=>{
      console.log(res,888)
        this.tableData=res
        this.loading = false;
        })
         setTimeout(() => {
          this.loading = false;
        }, 30000);
      
    },
    // 添加黑名单
    getBlack(row){
      let form = new FormData();
      form.append("createBy", row.createBy);
      form.append("createTime", row.createTime);
      form.append("updateBy", row.updateBy);
      form.append("ip", row.ip);
      form.append("name", row.name);
      form.append("updateTime", row.updateTime);
      form.append("remark", row.remark);
      form.append("id", row.id);
      form.append("delFlag", row.delFlag);
      form.append("deviceId", row.deviceId);
      form.append("mac", row.mac);
      form.append("status",row.status)
       form.append("statusCode","black")
      deviceUserOperation(form).then((res=>{
      this.refresh()
     }))
    },
    // 剔除用户dis_conn
     getDisconn(row){
      let form = new FormData();
      form.append("createBy", row.createBy);
      form.append("createTime", row.createTime);
      form.append("updateBy", row.updateBy);
      form.append("ip", row.ip);
      form.append("name", row.name);
      form.append("updateTime", row.updateTime);
      form.append("remark", row.remark);
      form.append("id", row.id);
      form.append("delFlag", row.delFlag);
      form.append("deviceId", row.deviceId);
      form.append("mac", row.mac);
      form.append("status",row.status)
      form.append("statusCode","dis_conn")
    deviceUserOperation(form).then((res=>{
    this.refresh()
     }))

     },
     // 解除黑名单conn
    getDisblack(row){
      let form = new FormData();
      form.append("createBy", row.createBy);
      form.append("createTime", row.createTime);
      form.append("updateBy", row.updateBy);
      form.append("ip", row.ip);
      form.append("name", row.name);
      form.append("updateTime", row.updateTime);
      form.append("remark", row.remark);
      form.append("id", row.id);
      form.append("delFlag", row.delFlag);
      form.append("deviceId", row.deviceId);
      form.append("mac", row.mac);
      form.append("status",row.status)
      form.append("statusCode","conn")
    deviceUserOperation(form).then((res=>{
    this.refresh()
     }))

     },
      onPageChange(page) {
      this.page = page;
      this.syncList()
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.syncList()
    },
     gethideWifi(item) {
      this.formListTwo.deviceCodeId = item.deviceId;
      this.formListTwo.wifiCodeIndex = item.wifiIndex;
      this.formListTwo.wifiCodeType = item.wifiType;
      // this.formList.showWifi=showWifi
      hideOperationSetWifi(
        this.formListTwo.deviceCodeId,
        this.formListTwo.wifiCodeIndex,
        this.formListTwo.wifiCodeType,
        this.formListTwo.hideWifi
      ).then((response) => {
        if (response.code == "0") {
          this.$modal.msgSuccess("操作成功");
          this.$router.push("/equipmentManagement/taskList");
        }
      });
    },
    getShowWifi(item) {
      this.formList.deviceCodeId = item.deviceId;
      this.formList.wifiCodeIndex = item.wifiIndex;
      this.formList.wifiCodeType = item.wifiType;
      showOperationSetWifi(
        this.formList.deviceCodeId,
        this.formList.wifiCodeIndex,
        this.formList.wifiCodeType,
        this.formList.showWifi
      ).then((response) => {
        if (response.code == "0") {
          this.$modal.msgSuccess("操作成功");
          this.$router.push("/equipmentManagement/taskList");
        }
      });
    },
    cancel() {
      this.dialogVisible = false;
    },
    // 修改名称
    focusSsid(e) {
      e.oldSsid = e.ssid;
    },
    ssidBlur(e) {
      if (e.ssid !== e.oldSsid) {
        this.$confirm("是否修改名称", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
               //var re=/^[\u4e00-\u9fa5_a-zA-Z0-9-_.]{1,32}$/
               var re=/^[0-9a-zA-Z_.-]{1,32}$/
            if (!re.test(e.ssid)){
               this.$alert("只能为1到32位,包含数字字母下划线点连接符", "提示", {
                  confirmButtonText: "确定",
                })
                e.ssid = e.oldSsid;
            }else{
              operationSetSsid("setSsid", e.deviceId, e.wifiIndex, e.ssid).then(
              (res) => {
                if (res.code == "0") {
                  this.$modal.msgSuccess("修改成功");
                  this.$router.push("/equipmentManagement/taskList");
                }
              }
            );
            }
            // operationSetSsid("setSsid", e.deviceId, e.wifiIndex, e.ssid).then(
            //   (res) => {
            //     if (res.code == "0") {
            //       this.$modal.msgSuccess("修改成功");
            //       this.$router.push("/equipmentManagement/taskList");
            //     }
            //   }
            // );
          })
          .catch(() => {
            e.ssid = e.oldSsid;
          });
      }
    },

    // 修改密码
    focusPassword(e) {
      e.oldPassword = e.password;
    },
    passwordBlur(e) {
      if (e.password !== e.oldPassword) {
        this.$confirm("是否修改密码", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            var re=/^[a-zA-Z0-9!@#%*&]{8,64}$/;
              if(!re.test(e.password)){
              this.$alert("只能为英文数字以及!@#%*&符号且长度为8到64位", "提示", {
              confirmButtonText: "确定",
              })
               e.password = e.oldPassword;
              }else{
              operationSetPwd("setPwd", e.deviceId, e.wifiIndex, e.password).then(
              (res) => {
                if (res.code == "0") {
                  this.$modal.msgSuccess("修改成功");
                  this.$router.push("/equipmentManagement/taskList");
                }
              }
            );  
              }
            // operationSetPwd("setPwd", e.deviceId, e.wifiIndex, e.password).then(
            //   (res) => {
            //     if (res.code == "0") {
            //       this.$modal.msgSuccess("修改成功");
            //       this.$router.push("/equipmentManagement/taskList");
            //     }
            //   }
            // );
          })
          .catch(() => {
            e.password = e.oldPassword;
          });
      }
    },

    // 修改加密方式
    focusType(e) {
      this.Encryptions = [];
      let a_encryptTypeList = e.encryptTypeList.split(",");
      a_encryptTypeList.forEach((itemc) => {
        this.Encryptions.push({
          label: itemc,
          value: itemc,
        });
      });
      e.oldEncryptType = e.encryptType;
    },
    typeBlur(e, index) {
      if (e.encryptType != e.oldEncryptType) {
        this.$confirm("是否进行修改", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            operationSetType(
              "setEncrypt",
              e.deviceId,
              e.wifiIndex,
              e.encryptType
            ).then((res) => {
              if (res.code == "0") {
                this.$modal.msgSuccess("修改成功");
                this.$router.push("/equipmentManagement/taskList");
              }
            });
          })
          .catch(() => {
            this.wifiCodeList = JSON.parse(
              JSON.stringify(this.queryDataList)
            ).wifiList;
          });
      }
    },
   
    // getMaterialsList() {
    //     let params = {
    //     pageSize: this.pageSize,
    //     pageNo: this.page,
    //   };
    //   if(this.formInline.spec!==""){
    //    params.spec=this.formInline.spec
    //   }
    //   if(this.formInline.material!==""){
    //    params.material=this.formInline.material
    //   }
     
    //   getFeepageList(params).then((res) => {
    //     this.tableData = res.rows
    //     this.total = res.count;
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped>
// .content-box{
//   margin-left: 40px;
//   margin-top: 30px;
// }
.m-title {
	color: #676a6c;
	font-weight: 700;
	font-size: 14px;
  height: 60px;
  line-height: 60px;
  padding-left: 50px;
  border-bottom:1px solid #d1d4d6;
}
.materialsFee .el-form-item__content{width: 206px;
    display: inline-block;}
.materialsList .el-form-item__label {vertical-align: top;}

.choose-title {
  height: 50px;
  line-height: 50px;
  color: rgb(255, 151, 2);
}
// .content-box {
//   margin-left: 25px;
//   margin-top: 10px;
// }
.choose-option {
  margin-top: 10px;
  height: 230px;
  background: #fff;
}
.ugnegOne {
  background: #fff;
  padding-top: 10px;
  padding-left: 20px;
}
::v-deep .el-descriptions__header {
  padding-top: 10px;
  padding-left: 20px;
  margin-bottom: 0px;
}
::v-deep .ugnegOne .el-descriptions .el-descriptions__body {
  background: #fff;
}
::v-deep .ugnegOne .el-descriptions .el-descriptions__body {
  height: auto;
  background: #fff;
}
::v-deep
  .ugnegOne
  .el-descriptions--medium:not(.is-bordered)
  .el-descriptions-item__cell {
  padding-bottom: 0px;
  padding-left: 25px;
}
::v-deep .ugnegOne .has-colon {
  padding-top: 20px;
  font-weight: 600;
}
.wifiButton {
  position: absolute;
  right: 3%;
}
.wifiButtonOne {
  margin-right: 15px;
  background: #f06400;
  border-color: #f06400;
}
::v-deep.el-input--small .el-input__inner {
  width: 150px;
}
</style>