<template>
  <div class="app-container">
    <div class="content-box">
      <div v-show="cardList && cardList.length != 0" class="content-boxOne">
        <div
          class="conter-boder"
          :style="{
            opacity: item.simId == cardListOne.currentSimId ? '1' : '.5',
          }"
          v-for="(item, index) in cardList"
          :key="index"
          @click="getSwitchCard(item)"
        >
          <!-- :style="{'opacity':item.simPresent=== '1'?'.5':'1'}" -->
          <!-- <div class="card_information"> -->
          <!-- <svg-icon icon-class="telecom" class="card_unicom" style="height: 50px;width: 40px;"/> -->
          <!-- <svg-icon icon-class="unicom" class="card_unicom" style="height: 40px;width: 40px;"/> -->
          <!-- <svg-icon icon-class="mobile" class="card_unicom" style="height: 40px;width: 40px;"/> -->
          <p
            style="
              color: red;
              font-weight: 600;
              font-size: 16px;
              text-align: center;
            "
          >
            {{ item.simName }}
          </p>
          <p>运营商:{{ item.operator }}</p>
          <!-- </div> -->
          <p>{{ item.simType === "1" ? "贴片卡" : "插拔卡" }}</p>
          <p>ICCID:{{ item.simIccid }}</p>
          <p style="color: green; font-weight: 600">
            {{ item.simPresent === "1" ? "在位" : "不在位" }}
          </p>
        </div>
      </div>
      <div class="conter-boder" @click="getIntelligentd" style="opacity: 0.5">
        <p class="Intelligent">智能选网</p>
      </div>
    </div>
  </div>
</template>

<script>
import { operationSwitchCard } from "@/api/devices/devices.js";
export default {
  props: ["queryDataList"],
  data() {
    return {
      cardListOne: "",
      cardList: [],
      nowid: false,
    };
  },
  watch: {
    queryDataList(n, o) {
      this.cardListOne = n;
      this.cardList = this.cardListOne.simList;
      this.operatorCount();
    },
  },

  created() {
    //this.query_data = JSON.parse(this.$route.query.data);
    this.cardListOne = this.queryDataList;
    this.cardList = this.cardListOne.simList;
    this.operatorCount();
  },

  methods: {
    operatorCount() {
     if(this.cardList){
      this.cardList.forEach((constMot) => {
        let putt = constMot.simIccid.slice(4, 6);
        if (putt == "03" || putt == "11") {
          constMot.operator = "中国电信";
        } else if (putt == "06" || putt == "01" || putt == "09") {
          constMot.operator = "中国联通";
        } else if (putt == "15") {
          constMot.operator = "中国广电";
        } else {
          constMot.operator = "中国移动";
        }
      });
     }
    },
    getIntelligentd() {
      this.$confirm("是否进行切换操作", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let paramValueOne = new FormData();
          paramValueOne.append("deviceId", this.cardListOne.id);
          paramValueOne.append("mode", 0);
          operationSwitchCard(paramValueOne).then((res) => {
            if (res.code == "0") {
              this.$message({
                message: "提交请求成功",
                type: "success",
              });
              this.$router.push("/equipmentManagement/taskList");
            } else {
              this.$message({
                message: res.msg,
                type: "error",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    getSwitchCard(item) {
      console.log(item, 11);
      this.$confirm("是否进行切换操作", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let paramValue = new FormData();
          paramValue.append("deviceId", this.cardListOne.id);
          paramValue.append("simId", item.simId);
          paramValue.append("mode", 1);
          operationSwitchCard(paramValue).then((res) => {
            if (res.code == "0") {
              this.$message({
                message: "提交请求成功",
                type: "success",
              });
              this.$router.push("/equipmentManagement/taskList");
            } else {
              this.$message({
                message: res.msg,
                type: "error",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.content-boxOne {
  display: flex;
}
.content-box {
  display: flex;
  margin-top: 10px;
  margin-left: 10px;
}
.conter-boder {
  min-width: 220px;
  height: 180px;
  border: 2px solid rgb(182, 182, 230);
  text-align: center;
  font-size: 14px;
  padding-top: 10px;
  margin: 0 20px;
  p {
    height: 30px;
    line-height: 30px;
  }
}
.card_information {
  display: flex;
  .card_unicom {
    margin: 0 20px;
  }
}
.Intelligent {
  color: coral;
  font-weight: 600;
  font-size: 18px;
  line-height: 130px;
}
</style>