<template>
  <div class="app-container">
  <el-tabs  tab-position="left">
  <el-tab-pane label="基本信息">
    <positioning :queryDataList="queryDataList" ></positioning>
  </el-tab-pane>
   <el-tab-pane label="流量信息">
    <flowInformation  :queryDataList="queryDataList" ></flowInformation>
  </el-tab-pane>
  <!-- <el-tab-pane label="重启">
    <restart  :queryDataList="queryDataList" ></restart>
  </el-tab-pane>
  <el-tab-pane label="恢复出厂">
    <restoreFactory  :queryDataList="queryDataList" ></restoreFactory>
  </el-tab-pane>
  <el-tab-pane label="限速">
    <speedLimit :queryDataList="queryDataList" ></speedLimit>
  </el-tab-pane>
  <el-tab-pane label="切卡">
    <cheka  :queryDataList="queryDataList" ></cheka>
  </el-tab-pane> -->
  <!-- <el-tab-pane label="wifi设置">
  <wifiSettings  :queryDataList="queryDataList" ></wifiSettings>
  </el-tab-pane> -->
  <el-tab-pane label="wifi设置/终端设备">
  <terminalEquipment  :queryDataList="queryDataList" ></terminalEquipment>
  </el-tab-pane>
   <el-tab-pane label="其他操作">
  <other :queryDataList="queryDataList" ></other>
  </el-tab-pane>
</el-tabs>
</div>
</template>

<script>
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import {
  BmLocalSearch,
  BmNavigation,
  BmGeolocation,
  BmCityList,
} from "vue-baidu-map";
import positioning from "./components/positioning";
import flowInformation from "./components/flowInformation";
import restart from "./components/restart";
import restoreFactory from "./components/restoreFactory";
import speedLimit from "./components/speedLimit";
import cheka from "./components/cheka";
// import shutdown from "./components/shutdown";
import wifiSettings from "./components/wifiSettings";
import terminalEquipment from "./components/terminalEquipment";
import other from "./components/other";
import {
 newDevice 
} from "@/api/devices/devices.js";
// import {
//   deviceLatestData,
//   deviceSlaveList,
//   getById,
// } from "@/api/device/device";
const defaultInfo = {
  lng: 0,
  lat: 0,
  addressStr: "",
  title: "",
  province: "", // 省
  city: "", // 市
  district: "", // 区
};
export default {
 components: {
    BaiduMap,
    BmLocalSearch,
    BmNavigation,
    BmGeolocation,
    BmCityList,
    positioning,
    flowInformation,
    restart,
    restoreFactory,
    speedLimit,
    cheka,
    // shutdown,
    wifiSettings,
    terminalEquipment,
    other
  },
  
  data() {
    return {
      tableData: [],
      total: 0,
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        slaveId: "",
        variableName: "",
      },
      deviceInfo: {},
      //slaves: [],
      BMap:null,
      map:null,
      mapCenter: {
        lng: "117.09449483",
        lat: "36.20585804"
      },
      mapZoom:15,
      //缩放级别
      mapZoom: 13,
      //BMap类
      BMap: null,
      //地图对象
      map: null,
      //设备信息
      businessDetail: {
        address: "山东省泰安市泰山区望岳东路3号",
        longitude: "117.09449483", //经度
        latitude: "36.20585804", //纬度
      },
      businessDetail_copy: {
        longitude: "", //经度
        latitude: "", //纬度
      },
      isChangeBusinessDetail: false,
      keyword: "",
      searchResult: [], // 检索结果列表
      showResultFlag: true,
      selectInfo: Object.assign({}, defaultInfo),
      timer: null,
      dialogBaiduMap: false,
      queryData:{},
      queryDataList:[]

    };
  },
   created() {
    this.queryData = JSON.parse(this.$route.query.data);
    let id=this.queryData.id
    console.log(this.queryData.id,1111)
    newDevice(id).then((res) => {
        this.queryDataList = res
      }
    );
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-tabs--left .el-tabs__item.is-left{
height: 60px;
}
::v-deep .el-tabs--left .el-tabs__nav-wrap.is-left{
height: calc(100vh);
}
::v-deep .el-tabs__item.is-active {
color:#f06400
// color: rgb(255, 151, 2);    f06400
}
::v-deep .el-tabs__active-bar{
background-color:#f06400
}
::v-deep .el-tabs__item:hover{
color:#f06400  
}
</style>